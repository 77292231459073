import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import UserSmallCard from '../components/UserSmallCard'
import MessageCard from '../components/MessageCard'
import { StyledLink } from '../utils/style/Atoms'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Loader } from '../utils/style/Atoms'
import Header from '../components/Header'
import axios from "axios"
import ModifyIcon from '../assets/modify.png'
const CardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const ActivityContainer = styled.span`
`
const TopActivitiesWrapper = styled.span`
	display: grid;
	gap: 0px;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
`
const ActivityWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const InputTextWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: line;
	justify-content: center;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const Location = styled.span`
	width: 100%;
	color: ${colors.secondary};
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const ModifyIconIllustration = styled.img`
	max-width: 28px;
	max-height: 28px;
`
function ChatMessage() {
	const { context } = useContext(UserContext);
	const [notif_count, set_notif_count] = useState(0);
	const [new_chat_message, set_new_chat_message] = useState(false);
	const [chat_messages, set_chat_messages] = useState({});
	const [usersArray, setUsersArray] = useState(null);
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		setLoading('Chargement en cours...');
		get_chat_message();
		check_user_notifs();
		// document.querySelector("input").addEventListener("keyup", input_key_up);
		axios.get(`${Constants.BACKEND_URL}user/get_connected_user`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_connected_user : ", response.data);
				setUsersArray(response.data);
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error("get users error : ", error);
			});
	}, [])
	function get_chat_message() {
		console.log("get_chat_message");
		axios.get(`${Constants.BACKEND_URL}chat_message`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				setLoading(false);
				console.log("get_chat_message : ", response.data);
				set_chat_messages(response.data.reverse());
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}
	function check_user_notifs() {
		console.log("check_user_notifs");
		axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("check_user_notifs ", response.data);
				set_notif_count(response.data.notif_count);
				set_new_chat_message(false);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}
	/*		// does not work : send x10 the same message...
	function input_key_up(event) {
		if (event.code === "Enter") {
			console.log("send message : ", $('#message').val());
			send_message();
		}
	}*/
	function send_message() {
		if ($('#message').val()) {
			console.log("send message : ", $('#message').val());
			axios.post(`${Constants.BACKEND_URL}chat_message`, {
				"content": $('#message').val()
			}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("send message response : ", response.data);
					get_chat_message();
					check_user_notifs();
				}).catch(error => console.error(error));
			$('#message').val("");
		}
	}
	function edit_message(message_id) {
		console.log("edit_message id : " + message_id);
		//navigate('/update_message/' + message_id); return;; return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}chat_message/get_one/${message_id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				navigate(`/update_message/chat_message-${message_id}`); return;
			}).catch(error => {
				navigate(`/update_message/chat_message-${message_id}`); return;
			});
	}
	function refresh() {
		setLoading(true);
		get_chat_message();
	}
	function getAge(dateString) {
		if (!dateString) {
			return dateString;
		}
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	return (
		<div>
			<Header notif_count={notif_count} new_chat_message={new_chat_message} />
			<ActivityContainer>
				{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(chat_messages) && (
							chat_messages?.map((msg) => (
								<LoaderWrapper key={msg._id}>
									<MessageCard
										content={msg.content}
										created={msg.created}
										user_id={msg.userId}
										user_title={msg.user_title}
										user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
									/>
									{(msg.userId === context["user"]._id || context["user"].moderator)&& (
										<ModifyIconIllustration onClick={() => edit_message(msg._id)} src={ModifyIcon} />
									)}
								</LoaderWrapper>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				<InputTextWrapper>
					<StyledLink $isFullLink onClick={() => send_message()}>&gt;</StyledLink>
					<Location>
						<textarea id="message" rows={3} style={{ width: '99%' }} maxLength={1000} wrap="soft"
							placeholder="Ajouter un message visible pendant 10 jours par tous les utilisateurs connectés" />
					</Location>
				</InputTextWrapper>
				<LoaderWrapper>
					<StyledLink $isFullLink onClick={() => send_message()}>Envoyer</StyledLink>
					<StyledLink $isFullLink onClick={() => refresh()} >Rafraichir la page</StyledLink>
				</LoaderWrapper>
				<div>&nbsp;</div>
				{usersArray && usersArray.length > 1 ? (
					<div style={{ width: '100%' }}>{usersArray.length}&nbsp;Utilisateurs connectés dans les dernières 24h</div>
				) : ("")}
				<TopActivitiesWrapper>
					{usersArray && usersArray.length > 1 && usersArray?.map((user) => (
						<Link key={`user-${user._id}`} to={`/user/${user._id}`}>
							<UserSmallCard
								title={user.title}
								label={getAge(user.birthday)}
								picture={`${Constants.BACKEND_URL}images/${user.image}`}
							/>
						</Link>
					))}
				</TopActivitiesWrapper>
				<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>
			</ActivityContainer>
		</div>
	)
}

export default ChatMessage
