import styled from 'styled-components'
import colors from '../utils/style/colors'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../utils/context'
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
export const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-bottom: 0.2em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
function Logout() {
	const { context } = useContext(UserContext);
	localStorage.clear();
	context["user"] = undefined;
	context["auth_token"] = undefined;
	console.log("localStorage token : ", localStorage.getItem('token'));

	useEffect(() => {
		console.log("useEffect context user : ", context["user"]);
	}, [])
	return (
		<div>
			<Header notif_count={0} new_chat_message={false} />
			<div className="gros">Vous êtes bien déconnecté</div>
			<LoaderWrapper style={{ marginTop: '1em' }}>
				<StyledLink $isFullLink to={`/login`} >Se reconnecter</StyledLink>
			</LoaderWrapper>
		</div>
	)
}
export default Logout
