import { Component } from 'react'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import dateFormat from 'dateformat';
import Linkify from 'react-linkify';

const MessageCardTitle = styled.div`
	color: #ffffff;
	font-size: 0.8em;
	height: 1em;
	display: flex;
	align-items: center;
`
const MessageCardDateTitle = styled.div`
	color: #c6c2c2;
	font-size: 0.6em;
	height: 1em;
	display: flex;
	align-items: center;
`
const MessageCardContent = styled.div`
	white-space: pre-wrap; // render multi-lines text
	color: #ffffff;
	font-size: 0.8em;
`
const MessageCardWrapper = styled.div`
	display: flex;
	border-top: 1px dotted white;
	border-bottom: 1px dotted white;
	padding-right:0.3em;
`
const MessageCardUserWrapper = styled.div`
	display: flex;
	flex-direction: row; // column or row
	justify-content: space-around;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	padding-left: 1em;
`
const MessageCardUserTitleWrapper = styled.div`
	display: flex;
	flex-direction: column; // column or row
	justify-content: space-around;
	margin-left: 0.5em;
	margin-right: 8px;
`
const MessageCardContentWrapper = styled.div`
`
class PrivateMessageCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { content, created, user_title, user_image } = this.props
		return (
			<MessageCardWrapper>
				<MessageCardUserWrapper>
					<div className="center-cropped" style={{ backgroundImage: `url('${user_image}')`, width: '30px', height: '30px', borderRadius: '50%' }} />
					<MessageCardUserTitleWrapper>
						<MessageCardTitle>{user_title}</MessageCardTitle>
						<MessageCardDateTitle>{dateFormat(new Date(created), "d mmm HH:MM")}</MessageCardDateTitle>
					</MessageCardUserTitleWrapper>
				</MessageCardUserWrapper>
				<MessageCardContentWrapper>
					<MessageCardContent><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{content}</div></Linkify></MessageCardContent>
				</MessageCardContentWrapper>
			</MessageCardWrapper>
		)
	}
}

export default PrivateMessageCard
