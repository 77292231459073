import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { StyledLink } from '../utils/style/Atoms'
import axios from "axios"
import Header from '../components/Header'
import UserSmallCard from '../components/UserSmallCard'
const ActivityWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 1em 1em;
	background-color: ${colors.backgroundDark};
`
const ActivityDetails = styled.div`
	display: flex;
	flex-direction: column;
	color: white;
	width: 100%;
`
const ErrorTitle = styled.h1`
	font-size: 2em;
	margin: 0;
	color: #b33232;
	font-weight: 600;
	background-color: #74992e;
`
const Location = styled.span`
	color: ${colors.secondary};
	text-align: left;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
const TopUsersWrapper = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
`
function SendInvite() {
	const { id: queryId } = useParams();
	const { context } = useContext(UserContext);
	const [error, setError] = useState('');
	const [contact_list, set_contact_list] = useState("");
	const navigate = useNavigate();
	console.log("new render");
	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		get_contact_list();
	}, [])
	function get_contact_list(data) {
		axios.get(`${Constants.BACKEND_URL}user_link/all/${context["user"]._id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get get_contact_list : ", response.data);

				var contact_list = [];
				for (var contact of response.data) {
					console.log("contact : ", contact);
					if (contact.userId === context["user"]._id) {
						var contact_updated = contact;
						contact_updated.userId = contact.linked_userId;
						contact_updated.user_title = contact.linked_user_title;
						contact_updated.user_image = contact.linked_user_image;
						contact_updated.user_city = contact.linked_user_city;
						contact_list.push(contact_updated);
					} else {
						contact_list.push(contact);
					}
				}
				console.log("get contact_list : ", contact_list);
				set_contact_list(contact_list);
			}).catch(error => console.error(error));
	}
	function onClick(userId) {
		console.log("onClick userId: ", userId);
		var contact_list_updated = [];
		for (var contact of contact_list) {
			console.log("contact : ", contact);
			if (contact.userId === userId) {
				var contact_updated = contact;
				if (contact.border) {
					contact_updated.border = undefined;
				} else {
					contact_updated.border = "2px solid red";
				}
				contact_list_updated.push(contact_updated);
			} else {
				contact_list_updated.push(contact);
			}
		}
		console.log("get contact_list : ", contact_list);
		set_contact_list(contact_list_updated);
	}
	function sendInvite() {
		console.log("save ");
		var contact_id_to_invite = [];
		for (var contact of contact_list) {
			if (contact.border) {
				contact_id_to_invite.push(contact.userId);
			}
		}
		if (contact_id_to_invite.length === 0) {
			alert("Sélectionnez au moins un utilisateur");
			return;
		}
		if (!window.confirm("Envoyer les invitations ?")) {
			return;
		}
		var post_body = {};
		post_body.contact_id_to_invite = contact_id_to_invite;
		post_body.activity_id = queryId;
		post_body.send_mail = $('#send_mail').val();
		axios.post(`${Constants.BACKEND_URL}activity/send_invite`, post_body,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log(response.data);
				navigate(`/activity/${queryId}`); return;
			}).catch(error => {
				console.error(error);
				setError(error);
			});
	}
	function cancel() {
		if (!window.confirm("Annuler les invitations ?")) {
			return;
		}
		//navigate(`/activity/${queryId}`); return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				navigate(`/activity/${queryId}`); return;
			}).catch(error => {
				navigate(`/activity/${queryId}`); return;
			});
	}
	return (
		<div>
			<Header notif_count={0} new_chat_message={false} />
			<ActivityWrapper>
				<ActivityDetails>
					<TitleWrapper>
						<Location><StyledLink $isFullLink onClick={() => sendInvite()}>Envoyer les invitations</StyledLink></Location>
						<Location>&nbsp;</Location><Location>&nbsp;</Location>
						<Location><StyledLink $isFullLink onClick={() => cancel(false)}>Annuler</StyledLink></Location>
					</TitleWrapper>
					<TitleWrapper>
						<ErrorTitle>{error ? ("Erreur : " + error) : ("")}</ErrorTitle>&nbsp;
					</TitleWrapper>
					<TitleWrapper>
						<ErrorTitle>{Array.isArray(contact_list) && contact_list.length === 0 ?
							("Veuillez ajouter des utilisateurs en contact pour pouvoir les inviter") : ("")}</ErrorTitle>&nbsp;
					</TitleWrapper>
					<TopUsersWrapper>
						{Array.isArray(contact_list) &&
							contact_list?.map((contact) => (
								<div onClick={() => onClick(contact.userId)}>
									<UserSmallCard
										title={contact.user_title}
										label={""}
										picture={`${Constants.BACKEND_URL}images/${contact.user_image}`}
										border={contact.border}
									/>
								</div>
							))}
					</TopUsersWrapper>
					<TitleWrapper>
						<select id="send_mail" size="2" style={{ width: "25em" }} defaultValue="false">
							<option value="false">Ne pas envoyer de mail</option>
							<option value="true">Envoyer des mails d'invitation</option>
						</select>
					</TitleWrapper>
				</ActivityDetails>
			</ActivityWrapper>
		</div>
	)
}

export default SendInvite
