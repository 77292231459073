import { Component } from 'react'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import Linkify from 'react-linkify';

const PodiumCardTitle = styled.span`
	height: 1em;
	display: flex;
	align-items: center;
`
const PodiumCardContent = styled.div`
	white-space: pre-wrap; // render multi-lines text
	color: #ffffff;
	font-size: 0.9em;
	font-weight: bold;
	margin-top: 0.7em;
`
const PodiumCardWrapper = styled.div`
	display: flex;
	border-radius: 30px;
	background-color: ${colors.backgroundDark};
	width: 100%;
`
const PodiumCardUserWrapper = styled.div`
	display: flex;
	flex-direction: row; // column or row
	justify-content: space-around;
	padding-left: 1em;
`
const PodiumCardUserTitleWrapper = styled.span`
	display: flex;
	flex-direction: column; // column or row
	justify-content: space-around;
	margin-left: 0.5em;
	margin-right: 8px;
	width: 3em;
	color: #ffffff;
`
const PodiumCardContentWrapper = styled.div`
`
class PodiumCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { content, user_image, score } = this.props

		return (
			<PodiumCardWrapper>
				<PodiumCardUserWrapper>
					<PodiumCardUserTitleWrapper >{score}</PodiumCardUserTitleWrapper>
					<div className="center-cropped" style={{ backgroundImage: `url('${user_image}')`, width: '2.5em', height: '2.5em', borderRadius: '50%' }} />
					<PodiumCardTitle >&nbsp;&nbsp;</PodiumCardTitle>
				</PodiumCardUserWrapper>
				<PodiumCardContentWrapper>
					<PodiumCardContent ><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{content}</div></Linkify></PodiumCardContent>
				</PodiumCardContentWrapper>
				<PodiumCardTitle >&nbsp;&nbsp;</PodiumCardTitle>
			</PodiumCardWrapper>
		)
	}
}

export default PodiumCard
