import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import Logout from './pages/Logout'
import ResetPassword from './pages/ResetPassword'
import ChatMessage from './pages/ChatMessage'
import Notifications from './pages/Notifications'
import Podium from './pages/Podium'
import CreateActivity from './pages/CreateActivity'
import Activities from './pages/Activities'
import Activity from './pages/Activity'
import SendInvite from './pages/SendInvite'
import Review from './pages/Review'
import UpdateMessage from './pages/UpdateMessage'
import User from './pages/User'
import Users from './pages/Users'
import Error from './components/Error'
import GlobalStyle from './utils/style/GlobalStyle'
import { UserProvider } from './utils/context'
import BurgerMenu from './components/BurgerMenu'

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<UserProvider>
				<div id="outer-container">
					<BurgerMenu pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
					<main id="page-wrap">
						<GlobalStyle />
						<Routes>
							<Route path="/" element={<Activities />} />
							<Route path="/users" element={<Users />} />
							<Route path="/login" element={<Login />} />
							<Route path="/logout" element={<Logout />} />
							<Route path="/send_invite/:id" element={<SendInvite />} />
							<Route path="/reset_password/:id" element={<ResetPassword />} />
							<Route path="/chat" element={<ChatMessage />} />
							<Route path="/notifications" element={<Notifications />} />
							<Route path="/podium" element={<Podium />} />
							<Route path="/createActivity" element={<CreateActivity />} />
							<Route path="/activity/:id" element={<Activity />} />
							<Route path="/review/:id" element={<Review />} />
							<Route path="/update_message/:id" element={<UpdateMessage />} />
							<Route path="/user/:id" element={<User />} />
							<Route path="*" element={<Error />} />
						</Routes>
					</main>
				</div>
			</UserProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
)
