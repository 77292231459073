import { Component } from 'react'
import styled from 'styled-components'
import colors from '../utils/style/colors'
const CardLabel = styled.span`
	color: #ffffff;
	font-size: 0.8em;
	font-weight: normal;
`
const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0.6em;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	&:hover {cursor: pointer;}

`
const CardTitle = styled.div`
	color: #ffffff;
	font-size: 0.8em;
	font-weight: normal;
	align-self: center;
	height: 1.2em;
	display: flex;
	align-items: center;
`
class ActivityCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		const { picture, label, date_str, city } = this.props
		return (
			<CardWrapper onClick={this.setFavorite}>
				<CardLabel>{label}</CardLabel>
        <div className="center-cropped" style={{ backgroundImage: `url('${picture}')`, width: '9em', height: '9em', borderRadius: '15%' }} />
				<CardTitle>{date_str}</CardTitle>
        <CardTitle>{city}</CardTitle>
			</CardWrapper>
		)
	}
}
export default ActivityCard
