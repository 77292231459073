import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import ActivitySmallCard from '../components/ActivitySmallCard'
import ReviewCard from '../components/ReviewCard'
import UserSmallCard from '../components/UserSmallCard'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Loader } from '../utils/style/Atoms'
import { QRCodeSVG } from 'qrcode.react';
import PrivateMessageCard from '../components/PrivateMessageCard'
import axios from "axios"
import Linkify from 'react-linkify';
import Header from '../components/Header'
import dateFormat from 'dateformat';
import { i18n } from "dateformat";
import ModifyIcon from '../assets/modify.png'
i18n.dayNames = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
i18n.monthNames = ["Jan", "Fev", "Mars", "Avr", "Mai", "Juin", "Juil", "Aou", "Sep", "Oct", "Nov", "Dec",
	"Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
i18n.timeNames = ["a", "p", "am", "pm", "A", "P", "AM", "PM"];
const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-right: 0.3em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const TextLabels = styled.div`
	font-size: 0.8em;
`
const MessageCardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const MessagesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const Details = styled.h1`
	margin: 0.1em;
	font-size: 0.8em;
	color: #f5e4c7;
`
const UserWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const TopMessagesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const TopActivitiesWrapper = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
`
const TopReviewWrapper = styled.div`
	display: grid;
	gap: 0px;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
`
const HorizontalWrapper = styled.div`
	display: flex;
	flex-direction: line;
	margin-top: 0.8em;
	margin-bottom: 0.5em;
`
const HorizontalWrapperCenter = styled.div`
	display: flex;
	flex-direction: line;
	justify-content: center;
	margin-top: 0.8em;
	margin-bottom: 0.5em;
`
const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.5em;
	color: white;
	width: 87%;
	text-align: left;
`
const Title = styled.h1`
	margin: 0.1em;
	font-size: 1em;
`
const Location = styled.span`
	color: ${colors.secondary};
`
const InputMessage = styled.span`
	width: 100%;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
const CardImage = styled.img`
	width: 100%;
`
const PositionDiv = styled.img`
	position: absolute;
	border-radius: 50%;
`
const ModifyIconIllustration = styled.img`
	max-width: 42px;
	max-height: 42px;
`
function User() {
	var { id: queryId } = useParams();
	const { context } = useContext(UserContext);
	const [notif_count, set_notif_count] = useState(0);
	const [new_chat_message, set_new_chat_message] = useState(false);
	const [user_data, set_user_data] = useState({});
	const [is_user, set_is_user] = useState("");
	const [user_id, set_user_id] = useState("");
	const [link, setLink] = useState("");
	const [user_review, set_user_review] = useState("");
	const [user_review_score, set_user_review_score] = useState("");
	const [contact_list, set_contact_list] = useState("");
	const [private_messages, set_private_messages] = useState({});
	const [inputSelectedFile, setInputSelectFile] = useState('')
	const [edit_page, set_edit_page] = useState(false);
	const [is_blocked, set_is_blocked] = useState(false);
	const [activity_user_link_data, setactivity_user_link_data] = useState({});
	const [activity_user_link_data_future, setactivity_user_link_data_future] = useState({});
	const [max_activity_user_link_reached, set_max_activity_user_link_reached] = useState({});
	const [x_coordinate, set_x_coordinate] = useState(0);
	const [y_coordinate, set_y_coordinate] = useState(0);
	const [x_relative_coordinate, set_x_relative_coordinate] = useState(0);
	const [y_relative_coordinate, set_y_relative_coordinate] = useState(0);
	const [position_marker_size, set_position_marker_size] = useState(0);
	const marker_size_factor = 25;
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); context["login_url_redirect"] = "/user/" + queryId; return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		if (context["user"]) {
			set_is_user(queryId === context["user"]._id);
			var local_is_user = queryId === context["user"]._id;
		}
		set_user_id(queryId);
		get_user_data(local_is_user);
		axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("check_user_notifs ", response.data);
				set_notif_count(response.data.notif_count);
				set_new_chat_message(response.data.new_chat_message);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])
	function set_toulouse_map_marker_position(data) {
		if (data) {
			if (document.getElementById("Toulouse_Map")) {
				var marker_size = window.innerWidth / marker_size_factor;
				var rect = document.getElementById("Toulouse_Map").getBoundingClientRect();
				set_x_relative_coordinate(data.x_coord * window.innerWidth + window.scrollX - marker_size * 1.5);
				set_y_relative_coordinate(data.y_coord * window.innerWidth + window.scrollY - marker_size * 1.5 + rect.top);
			}
		} else {
			console.error("Error no data ", data);
		}
	}
	function get_user_data(local_is_user) {
		console.log("get one User " + queryId);
		axios.get(`${Constants.BACKEND_URL}user/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get User : ", response.data);
				console.log("get User queryid : ", queryId);
				if (response.data.disable && !(context["user"] && context["user"].moderator) && !local_is_user) {
					var restricted_user_info = {
						_id: response.data._id,
						title: response.data.title,
						created: response.data.created,
						blocked_by_users: response.data.blocked_by_users,
						disable: response.data.disable
					}
					set_user_data(restricted_user_info);
				} else {
					set_user_data(response.data);
				}
				if (response.data.blocked_by_users) {
					set_is_blocked(response.data.blocked_by_users.includes(context["user"]._id));
				}
				if (response.data.user_is_blocked) {
					return;
				}
				if (response.data.x_coord > 0) {
					var marker_size = window.innerWidth / marker_size_factor;
					set_position_marker_size(marker_size);
					set_toulouse_map_marker_position(response.data);
				}
				get_user_link_data(response.data);
			}).catch(error => {
				if (error.response && error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}
	function get_user_link_data(data) {
		axios.get(`${Constants.BACKEND_URL}user_link/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get userLinkData : ", response.data);
				var link = response.data;
				if (link === null) {
					link = "null"; // needed cause null.status give "TypeError: Cannot read properties of null (reading 'status')"
				}
				if (link.status === "request") {
					link.status = link.userId === context["user"]._id ? "request_to_user" : "request_from_user";
				}
				setLink(link);
				get_messages(data);
			}).catch(error => console.error(error));
	}
	function get_messages(data) {
		console.log("get_messages with user : ", queryId);
		axios.get(`${Constants.BACKEND_URL}private_message/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get private_message response : ", response.data);
				set_private_messages(response.data.reverse());
				get_activity_user_link_data(data);
			}).catch(error => console.error(error));
	}
	function get_activity_user_link_data(data) {
		console.log("get_activity_user_link_data : ", queryId);
		axios.get(`${Constants.BACKEND_URL}activity_user_link/user/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get activity_user_link_data : ", response.data);
				var activities = [];
				var activities_future = [];
				for (var activity of response.data) {
					Date.prototype.addHours = function (h) {
						this.setTime(this.getTime() + (h * 60 * 60 * 1000));
						return this;
					}
					const date_minus_two_hours = new Date().addHours(-2);
					var activity_date = new Date(activity.activity_date);
					if (date_minus_two_hours < activity_date) {
						activities_future.push(activity);
					} else {
						activities.push(activity);
					}
				}
				setactivity_user_link_data(activities);
				setactivity_user_link_data_future(activities_future);
				set_max_activity_user_link_reached(response.data.length === 100);
				get_user_reviews(data);
			}).catch(error => console.error(error));
	}
	function get_user_reviews(data) {
		axios.get(`${Constants.BACKEND_URL}review/user/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get get_user_reviews : ", response.data);
				set_user_review(response.data);
				var score_sum = 0;
				var review_number = 0;
				for (var review of response.data) {
					score_sum += review.rating;
					review_number++;
				}
				if (review_number > 0) {
					set_user_review_score((score_sum / review_number).toFixed(1) + "/5");
				} else {
					set_user_review_score("");
				}
				get_contact_list(data);
			}).catch(error => console.error(error));
	}
	function get_contact_list(data) {
		axios.get(`${Constants.BACKEND_URL}user_link/all/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get get_contact_list : ", response.data);

				var contact_list = [];
				for (var contact of response.data) {
					console.log("contact : ", contact);
					if (contact.userId == queryId) {
						var contact_updated = contact;
						contact_updated.userId = contact.linked_userId;
						contact_updated.user_title = contact.linked_user_title;
						contact_updated.user_image = contact.linked_user_image;
						contact_updated.user_city = contact.linked_user_city;
						contact_list.push(contact_updated);
					} else {
						contact_list.push(contact);
					}
				}
				console.log("get contact_list : ", contact_list);
				set_contact_list(contact_list);
				set_toulouse_map_marker_position(data);
			}).catch(error => console.error(error));
	}
	function create_user_link_data() {
		console.log("create_user_link_data : ", queryId);
		axios.post(`${Constants.BACKEND_URL}user_link`, {
			"linked_userId": queryId
		},
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("create_user_link_data : ", response.data);
				get_user_link_data();
			}).catch(error => console.error(error));
	}
	function update_user_link_data(status) {
		console.log("update_user_link_data : ", status);
		axios.put(`${Constants.BACKEND_URL}user_link`, {
			"_id": link._id,
			"userId": link.userId,
			"linked_userId": link.linked_userId,
			"status": status
		}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("update_user_link_data response : ", response.data);
				get_user_link_data();
			}).catch(error => console.error(error));
	}
	function delete_user_link_data() {
		console.log("delete_user_link_data : ", link);
		if (window.confirm("Supprimer le contact ?")) {
			axios.delete(`${Constants.BACKEND_URL}user_link/${link._id}`,
				{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("delete_user_link_data response : ", response.data);
					get_user_link_data();
				}).catch(error => console.error(error));
		}
	}
	function save() {
		console.log("save user : ", queryId);
		console.log("inputSelectedFile : ", inputSelectedFile);
		var user_data = {
			"id": user_id,
			"city": $('#city').val(),
			"title": $('#title').val(),
			"name": $('#name').val(),
			"birthday": $('#birthday').val(),
			"gender": $('#gender').val(),
			"surname": $('#surname').val(),
			"phone": $('#phone').val(),
			"description": $('#description').val(),
			"like": $('#like').val(),
			"dontlike": $('#dontlike').val(),
			"trips": $('#trips').val(),
			"occupation": $('#occupation').val(),
			"company": $('#company').val(),
			"studies": $('#studies').val(),
			"university": $('#university').val(),
			"language": $('#language').val(),
			"birthtown": $('#birthtown').val(),
			"relationship": $('#relationship').val(),
			"ilive": $('#ilive').val(),
			"kids": $('#kids').val(),
			"pets": $('#pets').val(),
			"smoke": $('#smoke').val(),
			"alcohol": $('#alcohol').val(),
			"vaccine": $('#vaccine').val(),
			"sport": $('#sport').val(),
			"diet": $('#diet').val(),
			"network": $('#network').val(),
			"sleep": $('#sleep').val(),
			"song": $('#song').val(),
			"movie": $('#movie').val(),
			"sexual_orientation": $('#sexual_orientation').val(),
			"looking_for": $('#looking_for').val(),
			"access": $('#access').val()
		}
		if (!user_data.title) {
			alert("Le Nom affiché est obligatoire");
			return;
		}
		setLoading(true);
		if (x_coordinate > 0) {
			user_data["x_coord"] = x_coordinate;
			user_data["y_coord"] = y_coordinate;
		} else if (x_coordinate === -1) {
			// Delete coordinate
			user_data["x_coord"] = 0;
			user_data["y_coord"] = 0;
		}
		const formData = new FormData();
		formData.append('image', inputSelectedFile[0]);
		formData.append('user', JSON.stringify(user_data));

		axios.put(`${Constants.BACKEND_URL}user`, formData,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}`, 'Content-Type': 'multipart/form-data' } })
			.then(response => {
				console.log("save User response : ", response.data);
				if (response.data && response.data.user) {
					context["user"] = response.data.user
					context["auth_token"] = response.data.token
					localStorage.setItem('user', JSON.stringify(response.data.user));
					localStorage.setItem('token', response.data.token);
				}
				setLoading(false);
				window.location.reload();
			}).catch(error => console.error(error));
	}
	function cancel() {
		if (window.confirm("Annuler les modifications ?")) {
			set_edit_page(false);
			setTimeout(() => {
				set_toulouse_map_marker_position(user_data);
			}, "200");
		}
	}
	function delete_account() {
		console.log("delete_account ");
		if (window.confirm(`Supprimer le compte ? :'(\nSVP dites moi ce qui ne vous plait pas :\n   ${Constants.DOMAINE}@gmail.com\n`)) {
			if (window.confirm(`Êtes-vous sûr ? Cette action est irréversible !\nSVP dites moi ce qui ne vous plait pas :\n   ${Constants.DOMAINE}@gmail.com\n`)) {
				axios.delete(`${Constants.BACKEND_URL}user`, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
					.then(response => {
						console.log("delete_account User response : ", response.data);
						localStorage.removeItem('user');
						localStorage.removeItem('token');
						window.location.reload();
					}).catch(error => console.error(error));
			}
		}
	}
	function set_godfather() {
		console.log("set_godfather");
		if (window.confirm("Déclarer comme parrain ? Cela lui donnera des points en récompense. Cette action ne peut être faite qu'une seule fois.")) {
			axios.post(`${Constants.BACKEND_URL}user/set_godfather`, {
				"_id": queryId,
				"title": user_data.title
			}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("set_godfather response : ", response.data);
					var user_local_data = context["user"];
					user_local_data.godfather_title = "user_data.title";
					localStorage.setItem('user', JSON.stringify(user_local_data));
					window.location.reload();
				}).catch(error => console.error(error));
		}
	}
	function send_message() {
		console.log("send_message to user : ", queryId);
		axios.post(`${Constants.BACKEND_URL}private_message`, {
			"target_userId": queryId,
			"content": $('#message').val()
		}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("save message response : ", response.data);
				get_messages(user_data);
			}).catch(error => console.error(error));
		$('#message').val("");
	}
	function getAge(dateString) {
		if (!dateString) {
			return dateString;
		}
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	function toDateString(date) {
		var pad = function (num) {
			return (num < 10 ? '0' : '') + num;
		};
		try {
			var date_object = new Date(date);
			return date_object.getFullYear() +
				'-' + pad(date_object.getMonth() + 1) +
				'-' + pad(date_object.getDate());
		} catch {
			return "";
		}
	}
	function slow_reload() {
		setTimeout(function () {
			window.location.reload();
		}, 100);
	}
	function saveCoordinate(e) {
		if (edit_page) {
			var rect = document.getElementById("Toulouse_Map").getBoundingClientRect();
			var x_coordinate_update = e.clientX / window.innerWidth;
			var y_coordinate_update = (e.clientY - rect.top) / window.innerWidth;
			var marker_size = window.innerWidth / marker_size_factor;
			set_position_marker_size(marker_size);
			set_x_coordinate(x_coordinate_update);
			set_y_coordinate(y_coordinate_update);
			set_x_relative_coordinate(e.pageX - marker_size * 1.5);
			set_y_relative_coordinate(e.pageY - marker_size * 1.5);
		}
	}
	function delete_coordinate() {
		set_x_coordinate(-1);
		set_y_coordinate(-1);
		set_x_relative_coordinate(-1);
		set_y_relative_coordinate(-1);
		set_position_marker_size(0);
	}
	function modify() {
		set_edit_page(true);
		setTimeout(() => {
			set_toulouse_map_marker_position(user_data);
		}, "200");
	}
	function edit_review(review_id) {
		console.log("edit_review id : " + review_id);
		//navigate('/review/' + review_id); return;; return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}review/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				navigate('/review/' + review_id); return;
			}).catch(error => {
				navigate('/review/' + review_id); return;
			});
	}
	function edit_message(message_id) {
		console.log("edit_message id : " + message_id);
		//navigate('/update_message/' + message_id); return;; return; // does not work but why ??
		axios.get(`${Constants.BACKEND_URL}private_message/get_one/${message_id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				navigate(`/update_message/private_message-${message_id}`); return;
			}).catch(error => {
				navigate(`/update_message/private_message-${message_id}`); return;
			});
	}
	function set_disable(disable) {
		var user_data = {
			"disable": disable,
			"userId": queryId
		}
		console.log("set_disable user : ", user_data);
		if (disable) {
			if (!window.confirm("Cher modérateur, etes-vous sur de vouloir désactiver cet utilisateur ? Il ne pourra plus faire d'actions sur le site !")) {
				return;
			}
		} else {
			if (!window.confirm("Cher modérateur, réactiver cet utilisateur ? Il pourra à nouveau utiliser le site !")) {
				return;
			}
		}
		axios.post(`${Constants.BACKEND_URL}user/set_disable`, user_data,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("set_disable User response : ", response.data);
				window.location.reload();
			}).catch(error => console.error(error));
	}
	function block_user(block) {
		var user_data = {
			"userId": queryId
		}
		console.log("block_user user : ", user_data);
		if (block) {
			if (!window.confirm("Etes-vous sur de vouloir bloquer cet utilisateur ? Il ne pourra plus voir votre profil ni vos sorties.")) {
				return;
			}
			axios.post(`${Constants.BACKEND_URL}user/block_user`, user_data,
				{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("block_user User response : ", response.data);
					window.location.reload();
				}).catch(error => console.error(error));
		} else {
			if (!window.confirm("Etes-vous sur de vouloir débloquer cet utilisateur ? Il pourra à nouveau voir votre profil et vos sorties.")) {
				return;
			}
			axios.post(`${Constants.BACKEND_URL}user/unblock_user`, user_data,
				{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("block_user User response : ", response.data);
					window.location.reload();
				}).catch(error => console.error(error));
		}
	}
	return (
		<div>
			<Header notif_count={notif_count} new_chat_message={new_chat_message} />
			<TopContainer>
				<UserDetails>
					<HorizontalWrapper>
						{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
						{edit_page && !isLoading && (
							<HorizontalWrapper>
								<Location><StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink></Location>
								<Location>&nbsp;</Location><Location>&nbsp;</Location>
								<Location><StyledLink $isFullLink onClick={() => cancel()}>Annuler</StyledLink></Location>
							</HorizontalWrapper>
						)}
						{link === "null" && !is_user && !is_blocked && !edit_page && (
							<StyledLink $isFullLink onClick={() => create_user_link_data()}>
								Ajouter aux contacts
							</StyledLink>
						)}
						{link.status === "request_from_user" && !edit_page && (
							<StyledLink $isFullLink onClick={() => update_user_link_data("valid")}>
								Accepter le contact
							</StyledLink>
						)}
						{link.status === "request_to_user" && !edit_page && (
							<StyledLink>
								Demande de contact envoyée
							</StyledLink>
						)}
						{link.status === "request_to_user" && !edit_page && (
							<StyledLink $isFullLink onClick={() => delete_user_link_data()}>
								Annuler la demande de contact
							</StyledLink>
						)}
						{link.status === "valid" && !edit_page && (
							<StyledLink $isFullLink onClick={() => delete_user_link_data()}>
								Retirer des contacts
							</StyledLink>
						)}
						{!is_user && context["user"] && !context["user"].godfather_title && !edit_page && (
							<StyledLink $isFullLink onClick={() => set_godfather()}>
								Déclarer comme parrain
							</StyledLink>
						)}
						{!is_user && context["user"] && context["user"].moderator && user_data.disable && !edit_page && (
							<StyledLink $isFullLink style={{ color: `red` }} onClick={() => set_disable(false)}>
								Réactiver
							</StyledLink>
						)}
						{!is_user && !is_blocked && link.status !== "valid" && !is_user && !edit_page && (
							<StyledLink $isFullLink style={{ color: `red` }} onClick={() => block_user(true)}>
								Bloquer
							</StyledLink>
						)}
						{!is_user && is_blocked && !edit_page && (
							<StyledLink $isFullLink style={{ color: `red` }} onClick={() => block_user(false)}>
								Débloquer
							</StyledLink>
						)}
					</HorizontalWrapper>
					<HorizontalWrapper style={{ marginTop: `0em`, marginBottom: '0.1em' }}>
						{(is_user || (context["user"] && context["user"].moderator)) && !edit_page && (
							<StyledLink $isFullLink onClick={() => modify()}>
								Modifier
							</StyledLink>
						)}
						{!is_user && context["user"] && context["user"].moderator && !user_data.disable && !is_user && !edit_page && (
							<StyledLink $isFullLink style={{ color: `red` }} onClick={() => set_disable(true)}>
								Désactiver
							</StyledLink>
						)}
					</HorizontalWrapper>
				</UserDetails>
				<UserWrapper>
					<a href={`${Constants.BACKEND_URL}images/${user_data.image}`}>
						<div className="center-cropped" style={{ backgroundImage: `url('${Constants.BACKEND_URL}images/${user_data.image}')`, width: '10em', height: '10em', borderRadius: '20%' }} />
					</a>
					<UserDetails>
						{edit_page && <TitleWrapper><Location><input type="file" onChange={(e) => setInputSelectFile(e.target.files)} /></Location><Title>Modifier l'image</Title></TitleWrapper>}
						{edit_page ? (<TitleWrapper>
							<Location><input id="title" defaultValue={user_data.title} /></Location><Title>Nom affiché</Title></TitleWrapper>
						) : (<Title>{user_data.title}</Title>)}

						{edit_page ? (<TitleWrapper>
							<Location><input id="name" defaultValue={user_data.name} /></Location><Title>Prénom</Title></TitleWrapper>
						) : ("")}
						{edit_page ? (<TitleWrapper>
							<Location><input id="surname" defaultValue={user_data.surname} /></Location><Title>Nom</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><span>{user_data.email}</span></Location></TitleWrapper>
						) : ("")}
						{edit_page ? (<TitleWrapper>
							<Location><input id="phone" placeholder="06XXXXXXXX" maxlength="10" defaultValue={user_data.phone} /></Location><Title>Téléphone</Title></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.moderator ? (<span><mark>&nbsp;Moderateur&nbsp;du&nbsp;site&nbsp;</mark></span>) : ("")}
						{!edit_page && user_data.disable ? (<span><mark>&nbsp;Utilisateur désactivé par un modérateur&nbsp;!!!&nbsp;</mark></span>) : ("")}
						{edit_page ? (<TitleWrapper>
							<Location><input id="city" defaultValue={user_data.city} /></Location><Title>Ville</Title></TitleWrapper>
						) : (<span>{user_data.city}</span>)}
						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="gender" >
									<option value="null" selected={user_data.gender === "null"} ></option>
									<option value="m" selected={user_data.gender === "m"} >Homme</option>
									<option value="f" selected={user_data.gender === "f"} >Femme</option>
								</select>
							</Location>
							<Title>Genre</Title>
						</TitleWrapper>
						) : ("")}
						{edit_page && user_data.birthday ? (<TitleWrapper>
							<Location><input id="birthday" type="date" defaultValue={toDateString(user_data.birthday)} /></Location><Title>Date de naissance</Title></TitleWrapper>
						) : ("")}
						{edit_page && !user_data.birthday ? (<TitleWrapper>
							<Location><input id="birthday" type="date" /></Location><Title>Date de naissance</Title></TitleWrapper>
						) : ("")}
						{user_data.birthday ? (
							<Details>{getAge(user_data.birthday)}&nbsp;Ans</Details>
						) : ("")}
						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="access">
									<option value="public" selected={user_data.access === "public"} >Tous les utilisateurs connectés</option>
									<option value="friends_only" selected={user_data.access === "friends_only"} >Amis seulement</option>
								</select>
							</Location><Details>Visibilité</Details></TitleWrapper>
						) : (<TitleWrapper><Details>Visibilité&nbsp;:&nbsp;&nbsp;</Details><TextLabels>
							{"public" === user_data.access ? ("Tous les utilisateurs connectés") : ("")}
							{"friends_only" === user_data.access ? ("Amis seulement") : ("")}</TextLabels>
						</TitleWrapper>)}
						{user_data.last_connection ? (
							<div>
								<TitleWrapper><Details>Derniere connexion &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(user_data.last_connection), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Derniere modification &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(user_data.modified), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Compte créé &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(user_data.created), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Nombre de vue &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.view_count}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Score du mois en cours&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.month_score}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Score du dernier mois&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.last_month_score}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Score de tous les temps&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.score}</TextLabels></TitleWrapper>
								{user_data.godfather_title && (
									<TitleWrapper><Details>Parrain &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.godfather_title}</TextLabels></TitleWrapper>
								)}
								{user_data.godfather_list && (
									<TitleWrapper><Details>Parrain de &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.godfather_list}</TextLabels></TitleWrapper>
								)}
							</div>
						) : ("")}
					</UserDetails>
				</UserWrapper>
				<UserWrapper>
					<UserDetails style={{ width: '100%' }}>
						<TitleWrapper>{edit_page ? ("") : (
							<Title><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{user_data.description}</div></Linkify></Title>
						)}
						</TitleWrapper>
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>J'aime&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="like" defaultValue={user_data.like} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.like ? (<span><span>J'aime&nbsp;:&nbsp;</span>{user_data.like}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Je&nbsp;n'aime&nbsp;pas&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="dontlike" defaultValue={user_data.dontlike} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.dontlike ? (<span><span>Je&nbsp;n'aime&nbsp;pas&nbsp;:&nbsp;</span>{user_data.dontlike}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Mes&nbsp;voyages&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="trips" defaultValue={user_data.trips} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.trips ? (<span><span>Mes&nbsp;voyages&nbsp;:&nbsp;</span>{user_data.trips}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Profession&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="occupation" defaultValue={user_data.occupation} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.occupation ? (<span><span>Profession&nbsp;:&nbsp;</span>{user_data.occupation}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Entreprise&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="company" defaultValue={user_data.company} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.company ? (<span><span>Entreprise&nbsp;:&nbsp;</span>{user_data.company}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Université&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="university" defaultValue={user_data.university} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.university ? (<span><span>Université&nbsp;:&nbsp;</span>{user_data.university}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Langues&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="language" defaultValue={user_data.language} placeholder="Français, Anglais, Espagnol ..." />
							</Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.language ? (<span><span>Langues&nbsp;:&nbsp;</span>{user_data.language}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Ville&nbsp;d'origine&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="birthtown" defaultValue={user_data.birthtown} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.birthtown ? (<span><span>Ville&nbsp;d'origine&nbsp;:&nbsp;</span>{user_data.birthtown}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Film&nbsp;préféré&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="movie" defaultValue={user_data.movie} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.movie ? (<span><span>Film&nbsp;préféré&nbsp;:&nbsp;</span>{user_data.movie}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}><Title style={{ width: '11em' }}>Chanson&nbsp;préférée&nbsp;:&nbsp;</Title>
							<Location style={{ width: '91%' }}><input style={{ width: '98%' }} id="song" defaultValue={user_data.song} /></Location></TitleWrapper>
						) : ("")}
						{!edit_page && user_data.song ? (<span><span>Chanson&nbsp;préférée&nbsp;:&nbsp;</span>{user_data.song}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Études&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="studies" style={{ width: '98%' }} >
									<option value="null" selected={user_data.studies === "null"} ></option>
									<option value="Bac" selected={user_data.studies === "Bac"} >Bac</option>
									<option value="Licence" selected={user_data.studies === "Licence"} >Licence</option>
									<option value="Master" selected={user_data.studies === "Master"} >Master</option>
									<option value="Doctorat" selected={user_data.studies === "Doctorat"} >Doctorat</option>
									<option value="Ingénieur" selected={user_data.studies === "Ingénieur"} >Ingénieur</option>
									<option value="Je suis un génie" selected={user_data.studies === "Je suis un génie"} >Je suis un génie</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.studies ? (<span><span>Études&nbsp;:&nbsp;</span>{user_data.studies}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Relation actuelle&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="relationship" style={{ width: '98%' }} >
									<option value="null" selected={user_data.relationship === "null"} ></option>
									<option value="Célibataire" selected={user_data.relationship === "Célibataire"} >Célibataire</option>
									<option value="Célibataire et fier de l'être" selected={user_data.relationship === "Célibataire et fier de l'être"} >Célibataire et fier de l'être</option>
									<option value="Célibataire mais j'aimerai bien être en couple" selected={user_data.relationship === "Célibataire mais j'aimerai bien être en couple"} >Célibataire mais j'aimerai bien être en couple</option>
									<option value="En couple" selected={user_data.relationship === "En couple"} >En couple</option>
									<option value="En relation libre" selected={user_data.relationship === "En relation libre"} >En relation libre</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.relationship ? (<span><span>Relation actuelle&nbsp;:&nbsp;</span>{user_data.relationship}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Je vis&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="ilive" style={{ width: '98%' }} >
									<option value="null" selected={user_data.ilive === "null"} ></option>
									<option value="Seul" selected={user_data.ilive === "Seul"} >Seul</option>
									<option value="En famille" selected={user_data.ilive === "En famille"} >En famille</option>
									<option value="En couple" selected={user_data.ilive === "En couple"} >En couple</option>
									<option value="En colloc" selected={user_data.ilive === "En colloc"} >En colloc</option>
									<option value="Avec mon fils" selected={user_data.ilive === "Avec mon fils"} >Avec mon fils</option>
									<option value="Avec ma fille" selected={user_data.ilive === "Avec ma fille"} >Avec ma fille</option>
									<option value="Avec mes enfants" selected={user_data.ilive === "Avec mes enfants"} >Avec mes enfants</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.ilive ? (<span><span>Je vis&nbsp;:&nbsp;</span>{user_data.ilive}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Enfants&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="kids" style={{ width: '98%' }} >
									<option value="null" selected={user_data.kids === "null"} ></option>
									<option value="J'adore les enfants" selected={user_data.kids === "J'adore les enfants"} >J'adore les enfants</option>
									<option value="J'aime les enfants" selected={user_data.kids === "J'aime les enfants"} >J'aime les enfants</option>
									<option value="J'ai des enfants" selected={user_data.kids === "J'ai des enfants"} >J'ai des enfants</option>
									<option value="Je veux des enfants" selected={user_data.kids === "Je veux des enfants"} >Je veux des enfants</option>
									<option value="Je veux encore des enfants" selected={user_data.kids === "Je veux encore des enfants"} >Je veux encore des enfants</option>
									<option value="J'aime les enfants, mais je n'en veux pas" selected={user_data.kids === "J'aime les enfants, mais je n'en veux pas"} >J'aime les enfants, mais je n'en veux pas</option>
									<option value="Les enfants c'est pas pour moi" selected={user_data.kids === "Les enfants c'est pas pour moi"} >Les enfants c'est pas pour moi</option>
									<option value="Je suis allergique aux enfants" selected={user_data.kids === "Je suis allergique aux enfants"} >Je suis allergique aux enfants</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.kids ? (<span><span>Enfants&nbsp;:&nbsp;</span>{user_data.kids}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Animaux&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="pets" style={{ width: '98%' }} >
									<option value="null" selected={user_data.pets === "null"} ></option>
									<option value="Chien" selected={user_data.pets === "Chien"} >Chien</option>
									<option value="Chat" selected={user_data.pets === "Chat"} >Chat</option>
									<option value="J'en ai" selected={user_data.pets === "J'en ai"} >J'en ai</option>
									<option value="J'en ai pas" selected={user_data.pets === "J'en ai pas"} >J'en ai pas</option>
									<option value="J'en ai pas mais j'adore" selected={user_data.pets === "J'en ai pas mais j'adore"} >J'en ai pas mais j'adore</option>
									<option value="J'en veux" selected={user_data.pets === "J'en veux"} >J'en veux</option>
									<option value="Je n'aime pas ça" selected={user_data.pets === "Je n'aime pas ça"} >Je n'aime pas ça</option>
									<option value="Je suis allergique aux animaux" selected={user_data.pets === "Je suis allergique aux animaux"} >Je suis allergique aux animaux</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.pets ? (<span><span>Animaux&nbsp;:&nbsp;</span>{user_data.pets}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Tabac&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="smoke" style={{ width: '98%' }} >
									<option value="null" selected={user_data.smoke === "null"} ></option>
									<option value="Je fume" selected={user_data.smoke === "Je fume"} >Je fume</option>
									<option value="Je fume un peu" selected={user_data.smoke === "Je fume un peu"} >Je fume un peu</option>
									<option value="Je fume beaucoup" selected={user_data.smoke === "Je fume beaucoup"} >Je fume beaucoup</option>
									<option value="J'essaye d'arrêter" selected={user_data.smoke === "J'essaye d'arrêter"} >J'essaye d'arrêter</option>
									<option value="Je vapote" selected={user_data.smoke === "Je vapote"} >Je vapote</option>
									<option value="Je suis non fumeur" selected={user_data.smoke === "Je suis non fumeur"} >Je suis non fumeur</option>
									<option value="Je suis allergique au tabac" selected={user_data.smoke === "Je suis allergique au tabac"} >Je suis allergique au tabac</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.smoke ? (<span><span>Tabac&nbsp;:&nbsp;</span>{user_data.smoke}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Alcool&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="alcohol" style={{ width: '98%' }} >
									<option value="null" selected={user_data.alcohol === "null"} ></option>
									<option value="J'aime l'alcool" selected={user_data.alcohol === "J'aime l'alcool"} >J'aime l'alcool</option>
									<option value="J'aime l'alcool avec modération" selected={user_data.alcohol === "J'aime l'alcool avec modération"} >J'aime l'alcool avec modération</option>
									<option value="L'alcool c'est pas pour moi" selected={user_data.alcohol === "L'alcool c'est pas pour moi"} >L'alcool c'est pas pour moi</option>
									<option value="Je suis allergique à l'alcool" selected={user_data.alcohol === "Je suis allergique à l'alcool"} >Je suis allergique à l'alcool</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.alcohol ? (<span><span>Alcool&nbsp;:&nbsp;</span>{user_data.alcohol}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Sport&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="sport" style={{ width: '98%' }} >
									<option value="null" selected={user_data.sport === "null"} ></option>
									<option value="J'adore le sport" selected={user_data.sport === "J'adore le sport"} >J'adore le sport</option>
									<option value="J'en fait régulièrement" selected={user_data.sport === "J'en fait régulièrement"} >J'en fait régulièrement</option>
									<option value="J'en fait un peu" selected={user_data.sport === "J'en fait un peu"} >J'en fait un peu</option>
									<option value="Je n'en fait pas" selected={user_data.sport === "Je n'en fait pas"} >Je n'en fait pas</option>
									<option value="Je suis allergique au sport" selected={user_data.sport === "Je suis allergique au sport"} >Je suis allergique au sport</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.sport ? (<span><span>Sport&nbsp;:&nbsp;</span>{user_data.sport}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Vaccin COVID&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="vaccine" style={{ width: '98%' }} >
									<option value="null" selected={user_data.vaccine === "null"} ></option>
									<option value="Je suis pour" selected={user_data.vaccine === "Je suis pour"} >Je suis pour</option>
									<option value="Je suis contre" selected={user_data.vaccine === "Je suis contre"} >Je suis contre</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.vaccine ? (<span><span>Vaccin COVID&nbsp;:&nbsp;</span>{user_data.vaccine}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Régime&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="diet" style={{ width: '98%' }} >
									<option value="null" selected={user_data.diet === "null"} ></option>
									<option value="Omnivore" selected={user_data.diet === "Omnivore"} >Omnivore</option>
									<option value="Végétarien" selected={user_data.diet === "Végétarien"} >Végétarien</option>
									<option value="Végétalien" selected={user_data.diet === "Végétalien"} >Végétalien</option>
									<option value="Halal" selected={user_data.diet === "Halal"} >Halal</option>
									<option value="Casher" selected={user_data.diet === "Casher"} >Casher</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.diet ? (<span><span>Régime&nbsp;:&nbsp;</span>{user_data.diet}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Réseaux sociaux&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="network" style={{ width: '98%' }} >
									<option value="null" selected={user_data.network === "null"} ></option>
									<option value="Influenceur" selected={user_data.network === "Influenceur"} >Influenceur</option>
									<option value="Je poste régulièrement" selected={user_data.network === "Je poste régulièrement"} >Je poste régulièrement</option>
									<option value="Je regarde sans poster" selected={user_data.network === "Je regarde sans poster"} >Je regarde sans poster</option>
									<option value="Je n'y suis pas" selected={user_data.network === "Je n'y suis pas"} >Je n'y suis pas</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.network ? (<span><span>Réseaux sociaux&nbsp;:&nbsp;</span>{user_data.network}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Habitudes de sommeil&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="sleep" style={{ width: '98%' }} >
									<option value="null" selected={user_data.sleep === "null"} ></option>
									<option value="Lève-tôt" selected={user_data.sleep === "Lève-tôt"} >Lève-tôt</option>
									<option value="Lève-tard" selected={user_data.sleep === "Lève-tard"} >Lève-tard</option>
									<option value="Ça dépend" selected={user_data.sleep === "Ça dépend"} >Ça dépend</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.sleep ? (<span><span>Habitudes de sommeil&nbsp;:&nbsp;</span>{user_data.sleep}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Orientation sexuelle&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="sexual_orientation" style={{ width: '98%' }} >
									<option value="null" selected={user_data.sexual_orientation === "null"} ></option>
									<option value="Hétéro" selected={user_data.sexual_orientation === "Hétéro"} >Hétéro</option>
									<option value="Gay" selected={user_data.sexual_orientation === "Gay"} >Gay</option>
									<option value="Lesbienne" selected={user_data.sexual_orientation === "Lesbienne"} >Lesbienne</option>
									<option value="Bisexuel" selected={user_data.sexual_orientation === "Bisexuel"} >Bisexuel</option>
									<option value="Asexuel" selected={user_data.sexual_orientation === "Asexuel"} >Asexuel</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.sexual_orientation ? (<span><span>Orientation sexuelle&nbsp;:&nbsp;</span>{user_data.sexual_orientation}</span>) : ("")}
						{edit_page ? (<TitleWrapper style={{ width: '98%' }}>
							<Title style={{ width: '12em' }} >Je recherche des relations&nbsp;:&nbsp;</Title>
							<Location style={{ width: '98%' }}>
								<select id="looking_for" style={{ width: '98%' }} >
									<option value="null" selected={user_data.looking_for === "null"} ></option>
									<option value="Amicales" selected={user_data.looking_for === "Amicales"} >Amicales</option>
									<option value="Amoureuse" selected={user_data.looking_for === "Amoureuse"} >Amoureuse</option>
									<option value="De long terme" selected={user_data.looking_for === "De long terme"} >De long terme</option>
									<option value="De court terme" selected={user_data.looking_for === "De court terme"} >De court terme</option>
								</select>
							</Location>
						</TitleWrapper>
						) : ("")}
						{!edit_page && user_data.looking_for ? (<span><span>Je recherche des relations&nbsp;:&nbsp;</span>{user_data.looking_for}</span>) : ("")}
						<TitleWrapper>{edit_page ? (
							<textarea id="description" placeholder="Ecrivez ce que vous voulez !"
								rows={12} style={{ width: '98%' }} maxLength={10000} wrap="soft" defaultValue={user_data.description} />
						) : ("")}
						</TitleWrapper>
					</UserDetails>
				</UserWrapper>
				{!edit_page && activity_user_link_data_future.length && activity_user_link_data_future.length !== 0 ? (
					<div>{activity_user_link_data_future.length}&nbsp;sorties à venir</div>
				) : ("")}
				{!edit_page && (
					<TopActivitiesWrapper>
						{Array.isArray(activity_user_link_data_future) &&
							activity_user_link_data_future?.map((link) => (
								<Link key={`activity-${link._id}`} to={`/activity/${link.activityId}`}>
									<ActivitySmallCard
										title={link.activity_title}
										date_str={link.activity_date_str}
										picture={`${Constants.BACKEND_URL}images/${link.activity_image}`}
									/>
								</Link>
							))}
					</TopActivitiesWrapper>
				)}
				{!edit_page && !is_blocked && !is_user && ((user_data && "friends_only" !== user_data.access &&
					context["user"] && "friends_only" !== context["user"].access) || link.status === "valid" ||
					(context["user"] && context["user"].moderator) || (user_data.moderator)) && (
						<TopMessagesWrapper>
							<MessagesWrapper>
								<MessageCardsContainer>
									{Array.isArray(private_messages) && (
										private_messages?.map((msg) => (
											<HorizontalWrapper key={`msg-${msg._id}`}>
												<PrivateMessageCard
													content={msg.content}
													created={msg.created}
													user_title_hidden={(msg.userId === queryId) ? (user_data.title) : (context["user"].title)}
													user_image={(msg.userId === queryId) ? (`${Constants.BACKEND_URL}images/${user_data.image}`) : (`${Constants.BACKEND_URL}images/${context["user"].image}`)}
												/>
												{msg.userId === context["user"]._id && (
													<ModifyIconIllustration onClick={() => edit_message(msg._id)} src={ModifyIcon} />
												)}
											</HorizontalWrapper>
										)))}
								</MessageCardsContainer>
							</MessagesWrapper>
							<MessagesWrapper>
								<StyledLink $isFullLink onClick={() => send_message()}>&gt;</StyledLink>
								<InputMessage>
									<textarea id="message" rows={3} style={{ width: '98%' }} maxLength={1000} wrap="soft"
										placeholder="Ajouter un message privé" />
								</InputMessage>
							</MessagesWrapper>
							<UserWrapper>&nbsp;</UserWrapper>
						</TopMessagesWrapper>
					)}
				{!edit_page && activity_user_link_data.length && activity_user_link_data.length !== 0 && max_activity_user_link_reached ? (
					<div>Plus&nbsp;de&nbsp;{activity_user_link_data.length}&nbsp;sorties passées</div>
				) : ("")}
				{!edit_page && activity_user_link_data.length && activity_user_link_data.length !== 0 && !max_activity_user_link_reached ? (
					<div>{activity_user_link_data.length}&nbsp;sorties passées</div>
				) : ("")}
				{!edit_page && (
					<TopActivitiesWrapper>
						{Array.isArray(activity_user_link_data) &&
							activity_user_link_data?.map((link) => (
								<Link key={`activity-${link._id}`} to={`/activity/${link.activityId}`}>
									<ActivitySmallCard
										title={link.activity_title}
										date_str={link.activity_date_str}
										picture={`${Constants.BACKEND_URL}images/${link.activity_image}`}
									/>
								</Link>
							))}
						<UserWrapper>&nbsp;</UserWrapper>
					</TopActivitiesWrapper>
				)}
				{!edit_page && contact_list.length !== 0 && (
					<div>{contact_list.length}&nbsp;contacts</div>
				)}
				{!edit_page && (
					<TopActivitiesWrapper>
						{Array.isArray(contact_list) &&
							contact_list?.map((contact) => (
								<Link key={`user-${contact._id}`} to={`/user/${contact.userId}`} onClick={() => slow_reload()}>
									<UserSmallCard
										title={contact.user_title}
										label={""}
										picture={`${Constants.BACKEND_URL}images/${contact.user_image}`}
									/>
								</Link>
							))}
						<UserWrapper>&nbsp;</UserWrapper>
					</TopActivitiesWrapper>
				)}
				{!edit_page && user_review.length !== 0 && (
					<div>{user_review.length}&nbsp;avis sur les expériences vécues&nbsp;:&nbsp;{user_review_score}</div>
				)}
				{!edit_page && (
					<TopReviewWrapper>
						{Array.isArray(user_review) &&
							user_review?.map((review) => (
								<HorizontalWrapper key={`msg-${review._id}`}>
									<ReviewCard
										content={review.comment}
										rating={review.rating}
										activityId={review.activityId}
										user_id={review.userId}
										user_title={review.user_title}
										user_image={`${Constants.BACKEND_URL}images/${review.user_image}`}
									/>
									{review.userId === context["user"]._id && (
										<ModifyIconIllustration onClick={() => edit_review(review._id)} src={ModifyIcon} />
									)}
								</HorizontalWrapper>
							))}
						<div>&nbsp;</div>
					</TopReviewWrapper>
				)}
				{is_user && !edit_page && (
					<UserWrapper>
						<StyledLink $isFullLink onClick={() => delete_account()}>Supprimer votre compte</StyledLink>
					</UserWrapper>
				)}
			</TopContainer>
			{position_marker_size > 0 && !edit_page ? (
				<div>Position approximative :</div>
			) : ("")}
			{edit_page && user_data.google_image ? (
				<a href={`${user_data.google_image}`}><div style={{ textAlign: 'left' }}>Image Google</div>
					<div className="center-cropped" style={{ backgroundImage: `url('${user_data.google_image}')`, width: '10em', height: '10em', borderRadius: '20%' }} />
				</a>
			) : ("")}
			{edit_page && user_data.facebook_image ? (
				<a href={`${user_data.facebook_image}`}><div style={{ textAlign: 'left' }}>Image Facebook</div>
					<div className="center-cropped" style={{ backgroundImage: `url('${user_data.facebook_image}')`, width: '10em', height: '10em', borderRadius: '20%' }} />
				</a>
			) : ("")}
			{edit_page && x_coordinate >= 0 ? (
				<Location>
					<div>&nbsp;</div>
					<StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink>
					<StyledLink $isFullLink onClick={() => cancel()}>Annuler</StyledLink>
					<HorizontalWrapperCenter>
						<StyledLink $isFullLink onClick={() => delete_coordinate()}>Supprimer la position</StyledLink>
					</HorizontalWrapperCenter>
				</Location>
			) : ("")}
			{edit_page && x_coordinate >= 0 ? (
				<div>Sélectionne une position approximative, ATTENTION A NE PAS ZOOMER CAR CELA NE FONCTIONNE PAS AVEC LE ZOOM !</div>
			) : ("")}
			{position_marker_size > 0 || (edit_page && x_coordinate >= 0) ? (
				<CardImage id="Toulouse_Map" src={`${Constants.BACKEND_URL}images/Europe_map.jpg`} onClick={(e) => saveCoordinate(e)} alt="Toulouse_Map" />
			) : ("")}
			{x_relative_coordinate && x_relative_coordinate > 0 ? (
				<PositionDiv style={{
					top: `${y_relative_coordinate}px`, left: `${x_relative_coordinate}px`, position: "absolute",
					border: `${position_marker_size / 5}px solid red`, width: `${position_marker_size * 3}px`, height: `${position_marker_size * 3}px`
				}} />
			) : ("")}
			{!edit_page && (
				<div><div>Partager ce profil</div>
					<QRCodeSVG value={`https://${Constants.DOMAINE}/user/${user_id}`} marginSize='4' />
					<div>&nbsp;</div></div>
			)}
		</div>
	)
}
export default User
