import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { StyledLink } from '../utils/style/Atoms'
import axios from "axios"
import Header from '../components/Header'
const ActivityWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 1em 1em;
	background-color: ${colors.backgroundDark};
`
const ActivityDetails = styled.div`
	display: flex;
	flex-direction: column;
	color: white;
	width: 100%;
`
const ErrorTitle = styled.h1`
	font-size: 2em;
	margin: 0;
	color: #b33232;
	font-weight: 600;
	background-color: #74992e;
`
const Location = styled.span`
	color: ${colors.secondary};
	text-align: left;
`
const TextFullSize = styled.span`
	color: ${colors.secondary};
	text-align: left;
	width: 100%;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
function UpdateMessage() {
	const { id: queryId } = useParams();
	const { context } = useContext(UserContext);
	const [error, setError] = useState('');
	const [msg_data, set_msg_data] = useState({});
	const navigate = useNavigate();
	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		var query_split = queryId.split("-");
		var msg_url = query_split[0];
		var msg_id = query_split[1];
		axios.get(`${Constants.BACKEND_URL}${msg_url}/get_one/${msg_id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get review : ", response.data);
				set_msg_data(response.data);
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
				setError(error);
			});
	}, [])
	function save(action) {
		console.log("save " + action);
		var query_split = queryId.split("-");
		var msg_url = query_split[0];
		var msg_id = query_split[1];
		var message = {
			"_id": msg_id,
			"content": $('#content').val()
		};
		if (action === "delete") {
			if (window.confirm("Supprimer le message ?")) {
				message.content = "Message supprimé";
			} else {
				return;
			}
		}
		if (!message.content) {
			setError("Le message ne doit pas être vide.")
			return;
		}
		console.log("save : ", message);
		axios.put(`${Constants.BACKEND_URL}${msg_url}/update`, message,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("script put axiazdazdos");
				console.log(response.data);
				if (msg_url === "message") {
					navigate(`/activity/${msg_data.activityId}`); return;
				}
				if (msg_url === "private_message") {
					navigate(`/user/${msg_data.target_userId}`); return;
				}
				navigate(`/chat`); return;
			}).catch(error => {
				console.error(error);
				setError(error);
			});
	}
	function cancel() {
		if (!window.confirm("Annuler la modification ?")) {
			return;
		}
		//navigate(`/chat`); return; // does not work but why ??
		var query_split = queryId.split("-");
		var msg_url = query_split[0];
		var msg_id = query_split[1];
		axios.get(`${Constants.BACKEND_URL}${msg_url}/get_one/${msg_id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				if (msg_url === "message") {
					navigate(`/activity/${msg_data.activityId}`); return;
				}
				if (msg_url === "private_message") {
					navigate(`/user/${msg_data.target_userId}`); return;
				}
				navigate(`/chat`); return;
			}).catch(error => {
				setError(error);
				if (msg_url === "message") {
					navigate(`/activity/${msg_data.activityId}`); return;
				}
				if (msg_url === "private_message") {
					navigate(`/user/${msg_data.target_userId}`); return;
				}
				navigate(`/chat`); return;
			});
	}
	return (
		<div>
			<Header notif_count={0} new_chat_message={false} />
			<ActivityWrapper>
				<ActivityDetails>
					<TitleWrapper>
						<Location><StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink></Location>
						<Location>&nbsp;</Location>
						<Location><StyledLink $isFullLink onClick={() => cancel(false)}>Annuler</StyledLink></Location>
						<Location>&nbsp;</Location>
						<Location><StyledLink $isFullLink onClick={() => save("delete")}>Supprimer</StyledLink></Location>
					</TitleWrapper>
					<TitleWrapper>
						<ErrorTitle>{error ? ("Erreur : " + error) : ("")}</ErrorTitle>&nbsp;
					</TitleWrapper>
					<TitleWrapper>
						<TextFullSize><textarea id="content" placeholder="Message" defaultValue={msg_data.content}
							rows={12} style={{ width: '98%' }} maxLength={10000} wrap="soft" />
						</TextFullSize>
					</TitleWrapper>
				</ActivityDetails>
			</ActivityWrapper>
		</div>
	)
}

export default UpdateMessage
