import { pushRotate as Menu } from 'react-burger-menu' // slide / bubble / pushRotate / scaleRotate
import { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
export const StyledLink = styled(Link)`
padding-left: 2em;
padding-right: 3em;
padding-top: 0.8em;
padding-bottom: 0.8em;
color: #ffffff;
text-decoration: none;
font-size: 1.2em;
text-align: center;
`

var styles = {
	// Position and sizing of burger button
	bmBurgerButton: {
		position: 'fixed',
		width: '1.8em',
		height: '2em',
		left: '0.3em',
		top: '0.8em'
	},
	bmBurgerBars: {
		background: '#ffffff'
	},
	bmBurgerBarsHover: {
		background: '#a90000'
	},
	bmCrossButton: {
		height: '24px',
		width: '24px',
		padding: '20px'
	},
	bmCross: {
		background: '#bdc3c7',
		width: '6px',
		height: '28px'
	},
	bmMenuWrap: {
		position: 'fixed',
		height: '100%',
		width: '200px'
	},
	bmMenu: {
		background: '#373a47',
		fontSize: '1.15em'
	},
	bmMorphShape: {
		fill: '#373a47'
	},
	bmItemList: {
		color: '#b8b7ad',
		height: 0,
	},
	bmItem: {
		display: 'inline-block'
	},
	bmOverlay: {
		background: 'rgba(0, 0, 0, 0.3)'
	}
}

function confirm_log_out() {
	if (window.confirm("Déconnection ?")) {
		window.location.href = "/logout";
	}
}

class BurgerMenu extends Component {
	showSettings(event) {
		event.preventDefault();
	}

	render() {
		return (
			<Menu noTransition styles={styles} >
				<StyledLink to="/createActivity">
					Créer
				</StyledLink>
				<StyledLink $isFullLink onClick={() => confirm_log_out()}>Se&nbsp;déconnecter</StyledLink>
			</Menu>
		);
	}
}

export default BurgerMenu
