import { Component } from 'react'
import styled from 'styled-components'
import colors from '../utils/style/colors'
const CardLabel = styled.div`
	color: #ffffff;
	font-size: 0.7em;
	align-self: center;
	margin-top: 3px;
`
const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0px;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	&:hover {cursor: pointer;};
`
const CardTitle = styled.span`
	color: #ffffff;
	font-size: 0.7em;
	align-self: center;
`
class UserSmallCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		const { picture, title, label, border } = this.props
		return (
			<CardWrapper style={{ border: border }}>
				<div className="center-cropped" style={{
					backgroundImage: `url('${picture}')`,
					width: '80px', height: '80px', borderRadius: '15%', marginTop: '3px'
				}} />
				<CardTitle>{title}</CardTitle>
				{label ? (
					<CardLabel>{label}&nbsp;ans</CardLabel>
				) : ("")}
				{!label ? (
					<CardLabel>&nbsp;</CardLabel>
				) : ("")}
			</CardWrapper>
		)
	}
}
export default UserSmallCard
