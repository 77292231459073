import React, { useState, createContext } from 'react'

export const UserContext = createContext()
export const UserProvider = ({ children }) => {
	const [context, setUserContext] = useState({});
	const saveUserContext = (newUserContext) => {
		setUserContext({ ...context, ...newUserContext })
	}
	return (
		<UserContext.Provider value={{ context, saveUserContext }}>
			{children}
		</UserContext.Provider>
	)
}
