import styled from 'styled-components'
import $ from 'jquery';
import * as Constants from '../Constants';
import { Link } from 'react-router-dom'
import ActivityCard from '../components/ActivityCard'
import ActivitySmallCard from '../components/ActivitySmallCard'
import colors from '../utils/style/colors'
import { Loader } from '../utils/style/Atoms'
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../utils/context'
import Header from '../components/Header'
import axios from "axios"

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-items: center;
`
const SmallCardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	align-items: center;
	justify-items: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const PageSubtitle = styled.h2`
	font-size: 20px;
	color: ${colors.secondary};
	font-weight: 300;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
export const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-bottom: 0.2em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
function Activities() {
	const [activities_array, setActivityArray] = useState(null);
	const [activities_array_no_filter, setActivityArrayNoFilter] = useState(null);
	const [activities_array_number, set_activities_array_number] = useState(0);
	const [my_activities_array, setMyActivitiesArray] = useState(null);
	const [my_activities_array_no_filter, setMyActivitiesArrayNoFilter] = useState(null);
	const [my_contacts_activities_array, setMyContactsActivitiesArray] = useState(null);
	const [my_contacts_activities_array_no_filter, setMyContactsActivitiesArrayNoFilter] = useState(null);
	const [popular_activities_array, setPopularActivitiesArray] = useState(null);
	const [popular_activities_array_no_filter, setPopularActivitiesArrayNoFilter] = useState(null);
	const [is_filter_set, set_is_filter_set] = useState(false);
	const [is_user_connected, set_is_user_connected] = useState(false);
	const [notif_count, set_notif_count] = useState(0);
	const [new_chat_message, set_new_chat_message] = useState(false);
	const [isLoading, setLoading] = useState(null);
	const [error, setError] = useState(null);
	const { context } = useContext(UserContext)
	console.log("isLoading = ", isLoading)

	useEffect(() => {
		if (!context["auth_token"] && localStorage.getItem('user')) {
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		if (context["auth_token"]) {
			set_is_user_connected(true);
		} else {
			set_is_user_connected(false);
		}
		setLoading('Chargement en cours...');
		const source = axios.CancelToken.source();
		getAllActivities(source);
		if (localStorage.getItem('token')) {
			check_user_notifs();
		}
		return () => {
			source.cancel();
		}
	}, [])
	function getAllActivities(source) {
		console.log("getAllActivities");
		axios.get(`${Constants.BACKEND_URL}activity`)
			.then(response => {
				setLoading(false);
				console.log("response getAllActivities : ", response);
				var all_activities_unblock = response.data;
				var all_activities = []
				for (var activity_unblock of all_activities_unblock) {
					if (context["user"] && context["user"].blocked_by_users &&
						context["user"].blocked_by_users.includes(activity_unblock.userId)) {
						continue;
					}
					all_activities.push(activity_unblock);
				}
				setActivityArray(all_activities.slice(0, 50));
				set_activities_array_number(all_activities.length);
				setActivityArrayNoFilter(all_activities);
				var all_activities_sorted = all_activities.slice().sort((a, b) => b.current_user_number - a.current_user_number);
				var popular_activities = []
				var count = 0;
				for (var activity of all_activities_sorted) {
					if (count < 3) {
						count++;
						popular_activities.push(activity);
					}
				}
				setPopularActivitiesArray(popular_activities);
				setPopularActivitiesArrayNoFilter(popular_activities);
				if (localStorage.getItem('token')) {
					get_my_activities(source);
				}
			}).catch(error => {
				setLoading(false);
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});
	}
	function get_my_activities(source) {
		console.log("get_my_activities");
		axios.get(`${Constants.BACKEND_URL}activity_user_link/my_activities`,
			{ cancelToken: source.token, headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("response my_activities : ", response);
				//alert(JSON.stringify(response));
				setMyActivitiesArray(response.data);
				setMyActivitiesArrayNoFilter(response.data);
				//get_my_contacts_activities(source);
			}).catch(error => {
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});
	}
	function get_my_contacts_activities(source) {
		console.log("get_my_contacts_activities");
		axios.get(`${Constants.BACKEND_URL}activity/get_my_contacts_activities/my_contacts_activities`,
			{ cancelToken: source.token, headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("response get_my_contacts_activities : ", response);
				//alert(JSON.stringify(response));
				setMyContactsActivitiesArray(response.data);
				setMyContactsActivitiesArrayNoFilter(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});
	}
	function check_user_notifs() {
		axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("check_user_notifs ", response.data);
				set_notif_count(response.data.notif_count);
				set_new_chat_message(response.data.new_chat_message);
			}).catch(error => {
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});
	}
	function clearFilters() {
		$('#title_filter').val("");
		$('#type_filter').val("Tout");
		$('#date_filter').val("");
		processFilter("Tout", "", "");
	}
	function processFilter() {
		var activities_filtered_array = filter_activity_array(activities_array_no_filter);
		setActivityArray(activities_filtered_array);
		set_activities_array_number(activities_filtered_array.length);
		setPopularActivitiesArray(filter_activity_array(popular_activities_array_no_filter));
		if (localStorage.getItem('token')) {
			setMyActivitiesArray(filter_activity_array(my_activities_array_no_filter));
			setMyContactsActivitiesArray(filter_activity_array(my_contacts_activities_array_no_filter));
		}
		//console.log("activities_array filtered = %o", filter_activity_array(activities_array_no_filter));
		//console.log("popular_activities_array filtered = %o", filter_activity_array(popular_activities_array_no_filter));
		//console.log("my_activities_array filtered = %o", filter_activity_array(my_activities_array_no_filter));
		//console.log("my_contacts_activities_array filtered = %o", filter_activity_array(my_contacts_activities_array_no_filter));
	}
	function filter_activity_array(activities_array_without_filter) {
		var title_filter_value = $('#title_filter').val();
		var type_filter_value = $('#type_filter').val();
		var date_filter_value = $('#date_filter').val();
		set_is_filter_set(type_filter_value !== "Tout" || title_filter_value || date_filter_value);
		if (date_filter_value) {
			var date_filter_value_obj = new Date(date_filter_value);
			date_filter_value_obj.setHours(0, 0, 0, 0);
		}
		var activities_filtered = [];
		var activity_date_without_time;
		for (var activity of activities_array_without_filter) {
			var activity_date = activity.date || activity.activity_date;
			activity_date_without_time = new Date(activity_date);
			activity_date_without_time.setHours(0, 0, 0, 0);
			var title = activity.title || activity.activity_title;
			const accents = { 'é': 'e', 'è': 'e', 'ë': 'e', 'ï': 'i', 'ö': 'o', 'à': 'a', 'ù': 'u' };
			title = title.replace(/[éèëïöàù]/g, (char) => accents[char]).toUpperCase();
			title_filter_value = title_filter_value.replace(/[éèëïöàù]/g, (char) => accents[char]).toUpperCase();
			if (title.includes(title_filter_value) &&
				((activity.type && activity.type.includes(type_filter_value)) || type_filter_value === "Tout") &&
				(!date_filter_value_obj || date_filter_value_obj.getTime() === activity_date_without_time.getTime())) {
				activities_filtered.push(activity);
			}
		}
		return activities_filtered.slice(0, 50);
	}
	return (
		<div>
			<Header notif_count={notif_count} new_chat_message={new_chat_message} />
			{is_user_connected ? (
				<LoaderWrapper><StyledLink $isFullLink to={`/createActivity`} >Proposer une sortie</StyledLink></LoaderWrapper>
			) : ("")}
			<input id="title_filter" placeholder="&nbsp;Recherche" style={{ width: '7em' }} onChange={(e) => processFilter()} />&nbsp;&nbsp;
			<select id="type_filter" style={{ width: '7em' }} onChange={(e) => processFilter()}>
				<option value="Tout">Tout type</option>
				<option value="Divers">Divers</option>
				<option value="Jeux">Jeux</option>
				<option value="Sport">Sport</option>
				<option value="Repas">Repas</option>
				<option value="Boire un verre">Boire un verre</option>
				<option value="Spectacle">Spectacle</option>
				<option value="Danse">Danse</option>
				<option value="Enfants">Enfants</option>
			</select>&nbsp;&nbsp;
			<input id="date_filter" type="date" style={{ width: '8em' }} onChange={(e) => processFilter()} />
			{is_filter_set ? (
				<LoaderWrapper><StyledLink $isFullLink style={{ marginTop: '0.3em' }} onClick={(e) => clearFilters()} >Enlever les filtres</StyledLink></LoaderWrapper>
			) : ("")}
			{!is_user_connected && (
				<LoaderWrapper style={{ marginTop: '1em' }}>
					<StyledLink $isFullLink to={`/login`} >En voir plus</StyledLink>
				</LoaderWrapper>
			)}
			{my_activities_array && my_activities_array.length > 0 ? (
				<div style={{ width: '100%' }}>Mes&nbsp;sorties&nbsp;({my_activities_array.length})</div>
			) : ("")}
			{isLoading ? (
				<LoaderWrapper></LoaderWrapper>
			) : (
				<SmallCardsContainer>
					{my_activities_array?.map((activity) => (
						<Link key={`activity-${activity.activityId}`} to={`/activity/${activity.activityId}`}>
							<ActivitySmallCard
								title={activity.activity_title}
								date_str={activity.activity_date_str}
								picture={`${Constants.BACKEND_URL}images/${activity.activity_image}`}
							/>
						</Link>
					))}
				</SmallCardsContainer>
			)}
			{my_contacts_activities_array && my_contacts_activities_array.length > 0 ? (
				<div style={{ width: '100%' }}>{my_contacts_activities_array.length}&nbsp;sorties&nbsp;auxquelles&nbsp;participent&nbsp;mes&nbsp;contacts</div>
			) : ("")}
			{isLoading ? (
				<LoaderWrapper></LoaderWrapper>
			) : (
				<SmallCardsContainer>
					{my_contacts_activities_array?.map((activity) => (
						<Link key={`activity-${activity.activityId}`} to={`/activity/${activity.activityId}`}>
							<ActivitySmallCard
								title={activity.activity_title}
								date_str={activity.activity_date_str}
								picture={`${Constants.BACKEND_URL}images/${activity.activity_image}`}
							/>
						</Link>
					))}
				</SmallCardsContainer>
			)}
			{popular_activities_array && popular_activities_array.length > 0 ? (
				<div style={{ width: '100%' }}>Les 3 sorties&nbsp;les&nbsp;plus&nbsp;populaires</div>
			) : ("")}
			{isLoading ? (
				<LoaderWrapper></LoaderWrapper>
			) : (
				<CardsContainer>
					{popular_activities_array?.map((activity) => (
						<Link key={`activity-${activity._id}`} to={`/activity/${activity._id}`}>
							<ActivityCard
								label={activity.current_user_number + "/" + activity.max_user_number + " " + activity.title}
								date_str={activity.date_str}
								city={activity.city}
								picture={`${Constants.BACKEND_URL}images/${activity.image}`}
							/>
						</Link>
					))}
				</CardsContainer>
			)}
			{activities_array ? (
				<div style={{ width: '100%' }}>{activities_array_number}&nbsp;sorties publiques listées</div>
			) : ("")}
			{isLoading ? (
				<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>
			) : (
				<CardsContainer>
					{activities_array?.map((activity) => (
						<Link key={`activity-${activity._id}`} to={`/activity/${activity._id}`}>
							<ActivityCard
								label={activity.current_user_number + "/" + activity.max_user_number + " " + activity.title}
								date_str={activity.date_str}
								city={activity.city}
								picture={`${Constants.BACKEND_URL}images/${activity.image}`}
							/>
						</Link>
					))}
				</CardsContainer>
			)}
			<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>
			<PageSubtitle>{error && <p>{error}</p>}</PageSubtitle>
		</div>
	)
}

export default Activities
