import PropTypes from 'prop-types'
import { Component } from 'react'
import styled from 'styled-components'
import colors from '../utils/style/colors'

const CardTitle = styled.span`
	color: #ffffff;
	align-self: center;
	margin-left: 1em;
	font-size: 0.6em;
`
const CardDate = styled.div`
	color: #ffffff;
	font-size: 0.6em;
	align-self: center;
`
const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0px;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	&:hover {cursor: pointer;}
`
class ActivitySmallCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		const { picture, date_str, title } = this.props
		return (
			<CardWrapper onClick={this.setFavorite}>
				<CardDate>{date_str}</CardDate>
				<div className="center-cropped" style={{ backgroundImage: `url('${picture}')`, width: '80px', height: '80px', borderRadius: '15%', marginTop: '3px' }} />
				<CardTitle>{title}</CardTitle>
				<CardTitle>&nbsp;</CardTitle>
			</CardWrapper>
		)
	}
}

ActivitySmallCard.propTypes = {
	date: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	picture: PropTypes.string.isRequired,
}

ActivitySmallCard.defaultProps = {
	date: '',
	title: '',
}

export default ActivitySmallCard
