import styled from 'styled-components'
import { Component } from 'react'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
	padding: 1.4em 0.4em;
	margin-left: 1px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1em;
	text-align: center;
	border-radius: 20px; 
	background-color: #28292b;
`

const NavContainer = styled.nav`
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { notif_count, new_chat_message } = this.props;
		console.log("new_chat_message = ", new_chat_message);
		return (
			<NavContainer>
				<div>
					<span style={{ paddingLeft: '1.3em' }}></span>
					<StyledLink to="/">
						Sorties
					</StyledLink>
					<StyledLink to="/users">
						Utilisateurs
					</StyledLink>
					<StyledLink to="/podium">
						Podium
					</StyledLink>
					<StyledLink to="/chat">
						{new_chat_message && (<mark>Discuter</mark>)}
						{!new_chat_message && ("Discuter")}
					</StyledLink>
					<StyledLink to="/notifications">
						{notif_count !== 0 && (<mark>{notif_count}&nbsp;Notifs</mark>)}
						{notif_count === 0 && ("Notifs")}
					</StyledLink>
				</div>
			</NavContainer>
		)
	}
}

export default Header
