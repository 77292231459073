import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { StyledLink } from '../utils/style/Atoms'
import axios from "axios"
import { useNavigate } from "react-router-dom";
import Header from '../components/Header'
import './main.css';

const HomeWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const HomerContainer = styled.div`
	background-color: ${colors.backgroundDark};
	display: flex;
	width: 100%;
	flex-direction: row;
`

const LeftCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	${StyledLink} {
		max-width: 250px;
	}
`

const StyledTitle = styled.h2`
	padding-bottom: 30px;
	max-width: 280px;
	line-height: 50px;
	color: #ff6767;
`

function Login() {
	const { id: queryId } = useParams();
	const [password, setPassword] = useState('');
	const [error_msg, set_error_msg] = useState('');
	const navigate = useNavigate();


	function reset_password() {
		if (password.length < 6) {
			set_error_msg("Le mot de passe doit contenir au moins 6 caractères")
			return;
		}
		var res = queryId.split("_");
		console.log("reset_password id=" + res[0]);
		console.log("reset_password reset_password_send_mail=" + res[1]);
		axios.post(`${Constants.BACKEND_URL}user/reset_password`, {
			"id": res[0],
			"random_reset_password_token": res[1],
			"password": password
		}).then(response => {
			console.log("reset_password_send_mail done : ", response.data);
			set_error_msg("Mise à jour du mot de passe effectuée")
		}).catch(error => set_error_msg(JSON.stringify(error.response.data.message)));
	}

	return (
		<div>
			<Header notif_count={0} new_chat_message={false}/>
			<HomeWrapper>
				<HomerContainer>
					<LeftCol>
						<div class="montitre">Entrer un nouveau mot de passe</div>
						<input id="password" placeholder="XXXXXX" type="password" onChange={(e) => setPassword(e.target.value)} />
						<br></br>
						<StyledLink $isFullLink onClick={() => reset_password()}>
							Mettre à jour le mot de passe
						</StyledLink>
						<StyledTitle>
							{error_msg}
						</StyledTitle>
					</LeftCol>
				</HomerContainer>
			</HomeWrapper>
		</div>
	)
}

export default Login
