import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import { StyledLink } from '../utils/style/Atoms'
import axios from "axios"
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Loader } from '../utils/style/Atoms'
import Header from '../components/Header'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import './main.css';
import FacebookIcon from '../assets/facebook_icon.png'
const FacebookIconIllustration = styled.img`
	max-width: 15px;
`
const HomeWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const HomerContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
`
const LeftCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	${StyledLink} {
		max-width: 250px;
	}
`
const StyledTitle = styled.div`
	margin: 5px;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
function Login() {
	const [email, setMail] = useState('');
	const [password, setPassword] = useState('');
	const [error_msg, set_error_msg] = useState('');
	const [isLoading, setLoading] = useState(null);
	const { context } = useContext(UserContext);
	console.log("localStorage token : ", localStorage.getItem('token'));
	const navigate = useNavigate();
	useEffect(() => {
		// Navigate to / if already connected user
		if (context["auth_token"]) {
			navigate('/'); return;
		} else if (localStorage.getItem('token')) {
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
			navigate('/'); return;
		}
		// Vérifie si le script Google est déjà chargé
		if (!window.google) {
			const script = document.createElement("script");
			script.src = "https://accounts.google.com/gsi/client";
			script.async = true;
			script.defer = true;
			script.onload = initializeGoogleSignIn; // Appelle la fonction d'initialisation une fois chargé
			document.body.appendChild(script);
		} else {
			initializeGoogleSignIn();
		}
		function initializeGoogleSignIn() {
			if (window.google) {
				window.google.accounts.id.initialize({
					client_id: "520493252309-25epr1jbibkvo2qsfq3dllqc84umu8jc.apps.googleusercontent.com",
					callback: login_with_google_callback
				});
				window.google.accounts.id.renderButton(
					document.getElementById("g_id_signin"),
					{ theme: "outline", size: "large" }
				);
			}
		}
		console.log("Wake up server request");
		axios.get(`${Constants.BACKEND_URL}`).then(response => {
			console.log("Server is up :-) : ", response.data);
		}).catch(error => {
			console.log(error);
			var errMsg = JSON.stringify(error.response.data.error);
			set_error_msg(errMsg)
		});
		axios.post(`${Constants.BACKEND_URL}user/wake_up`).then(response => {
			console.log("Server is up :-) : %o", response.data);
		}).catch(error => {
			console.log("err : %o", error.message);
		});
	}, [])
	function register() {
		console.log("Register as " + email);
		if (email.length < 6) {
			set_error_msg("Le mail doit contenir au moins 6 caractères")
			return;
		}
		if (!email.includes("@")) {
			set_error_msg("Le mail doit contenir le caractère @")
			return;
		}
		if (password.length < 6) {
			set_error_msg("Le mot de passe doit contenir au moins 6 caractères")
			return;
		}
		const email_split = email.split('@');
		var title = email_split[0]
		console.log("User default title : ", title);

		setLoading('Enregistrement en cours...');
		axios.post(`${Constants.BACKEND_URL}user`, {
			"email": email, "password": password, "name": title, "title": title, "city": "Paris"
		}).then(response => {
			console.log("User created : ", response.data);
			login();
		}).catch(error => {
			setLoading(false);
			console.log(error);
			var errMsg = JSON.stringify(error.response.data.error);
			if (errMsg.includes("unique")) { errMsg = "Cette adresse mail existe déjà !" }
			set_error_msg(errMsg)
		});
	}
	function login() {
		console.log("Login as " + email);
		if (email.length < 1) {
			set_error_msg("Le mail doit contenir au moins 6 caractères")
			return;
		}
		if (!email.includes("@")) {
			set_error_msg("Le mail doit contenir le caractère @")
			//return;
		}
		setLoading('Chargement en cours...');
		axios.post(`${Constants.BACKEND_URL}user/login`, {
			"email": email, "password": password
		}).then(response => {
			setLoading(false);
			console.log("Login done : ", response.data);
			//setUserContext({ "loginToken": response.data.token });
			localStorage.setItem('user', JSON.stringify(response.data.user));
			localStorage.setItem('token', response.data.token);
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			if (context["login_url_redirect"]) { navigate(context["login_url_redirect"]); } else { navigate('/'); }
		}).catch(error => {set_error_msg(JSON.stringify(error.response.data.error)); setLoading(false);});
	}
	function login_with_google_callback(jsonGoogle) {
		console.log("login_with_google jsonGoogle ", jsonGoogle);
		setLoading('Chargement en cours...');
		axios.post(`${Constants.BACKEND_URL}user/login_with_google`, jsonGoogle)
			.then(response => {
				setLoading(false);
				console.log("Login done : ", response.data);
				localStorage.setItem('user', JSON.stringify(response.data.user));
				localStorage.setItem('token', response.data.token);
				context["user"] = JSON.parse(localStorage.getItem('user'));
				context["auth_token"] = localStorage.getItem('token');
				if (context["login_url_redirect"]) { navigate(context["login_url_redirect"]); } else { navigate('/'); }
			}).catch(error => {set_error_msg(JSON.stringify(error.response.data.error)); setLoading(false);});
	}
	function reset_password_send_mail() {
		console.log("reset_password_send_mail " + email);
		if (email.length < 6) {
			set_error_msg("Le mail doit contenir au moins 6 caractères")
			return;
		}
		if (!email.includes("@")) {
			set_error_msg("Le mail doit contenir le caractère @")
			return;
		}
		setLoading('Envoie de mail en cours...');
		axios.post(`${Constants.BACKEND_URL}user/reset_password_send_mail`, {
			"email": email
		}).then(response => {
			setLoading(false);
			console.log("reset_password_send_mail done : ", response.data);
			set_error_msg("Email de mise à jour de mot de passe envoyé")
		}).catch(error => {set_error_msg(JSON.stringify(error.response.data.message)); setLoading(false);});
	}
	const responseFacebook = async (response) => {
		console.log("responseFacebook");
		console.log(response);
		setLoading('Chargement en cours...');
		axios.post(`${Constants.BACKEND_URL}user/login_with_facebook`, response)
			.then(response => {
				setLoading(false);
				console.log("Login done : ", response.data);
				localStorage.setItem('user', JSON.stringify(response.data.user));
				localStorage.setItem('token', response.data.token);
				context["user"] = JSON.parse(localStorage.getItem('user'));
				context["auth_token"] = localStorage.getItem('token');
				if (context["login_url_redirect"]) { navigate(context["login_url_redirect"]); } else { navigate('/'); }
			}).catch(error => {set_error_msg(JSON.stringify(error.response.data.error)); setLoading(false);});
	}
	return (
		<div>
			<Header notif_count={0} new_chat_message={false} />
			{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
			{isLoading ? (<PageTitle>{<p>{isLoading}</p>}</PageTitle>) : ("")}
			<HomeWrapper>
				<HomerContainer>
					<LeftCol>
						<StyledTitle>
							<div id="g_id_signin"></div>
							<div style={{ marginTop: '5px' }}></div>
							<FacebookLogin
								appId="575453205524364"
								autoLoad={false}
								disableMobileRedirect={true}
								callback={responseFacebook}
								render={renderProps => (
									<button onClick={renderProps.onClick} style={{ padding: '5px', color: 'white', backgroundColor: '#3C5896' }}>
										<div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
											<FacebookIconIllustration src={FacebookIcon} />
											&nbsp;&nbsp;Se connecter avec Facebook
										</div></button>
								)}
							/>
						</StyledTitle>
						<div><mark>{error_msg}</mark></div>
						Email <input id="email" placeholder="example@gmail.com" onChange={(e) => setMail(e.target.value)} />
						<div style={{ padding: '5px' }}></div>
						Mot de passe <input id="password" placeholder="XXXXXX" type="password" onChange={(e) => setPassword(e.target.value)} />
						<div style={{ padding: '5px' }}></div>
						<StyledLink $isFullLink onClick={() => login()}>
							Se connecter
						</StyledLink>
						<div style={{ padding: '5px' }}></div>
						<StyledLink $isFullLink onClick={() => register()}>
							Crée un compte en un instant
						</StyledLink>
						<div style={{ padding: '5px' }}></div>
						<StyledLink $isFullLink onClick={() => reset_password_send_mail()}>
							Mettre à jour votre mot de passe
						</StyledLink>
						<h1 style={{ display: 'none' }}>Afterworks, auberges espagnoles, footing... 10€ à gagner !
							Sécurisé, rapide et gratuit.
							La meilleure solution pour organiser des sorties.
							Inspiré de Frimake, OnVaSortir, MeetUp et AmieZ</h1>
						<h1 style={{ display: 'none' }}>Ce site s'est librement inspiré d'autres plateformes :
							OnVaSortir, Frimake, Meetup, Tinder, Fruits, Bumble, Facebook, OVS, On va sortir, Amiez
							Strava, Instagram, TikTok, X twitter</h1>

						<h1 style={{ display: 'none' }}>Mots clés tags de recherche :
							sorties toulouse rencontres célibataires amour amis</h1>
						<h1 style={{ display: 'none' }}>Mots clés tags de recherche :
							love friends single meet activities</h1>
						<div style={{ padding: '5px' }}></div>
						<img style={{ maxWidth: '100%' }} src={`https://${Constants.DOMAINE}/backend/images/Flyer.png`} alt="Flyer"></img>
						<img style={{ maxWidth: '100%' }} src={`https://${Constants.DOMAINE}/backend/images/Flyer_avantages.png`} alt="Flyer"></img>
						<img style={{ display: 'none' }} src={`https://${Constants.DOMAINE}/backend/images/QR_Code.png`} alt="URL QR Code"></img>
						<div style={{ display: 'none' }} class="normal">&nbsp;</div>
						<div class="normal">{`${Constants.DOMAINE} recommande ces autres plateformes :`}</div>
						<div class="normal"><a style={{ color: 'yellow' }} href="https://frimake.com/">Frimake&nbsp;&nbsp;</a>
							<a style={{ color: 'yellow' }} href="https://www.entrepotes.org/">Entre Potes&nbsp;&nbsp;</a>
							<a style={{ color: 'yellow' }} href="https://www.amiez.org/">AmieZ&nbsp;&nbsp;</a>
							<a style={{ color: 'yellow' }} href="https://paris.onvasortir.com/">OnVaSortir&nbsp;&nbsp;</a>
							<a style={{ color: 'yellow' }} href="https://www.meetup.com/">Meetup&nbsp;&nbsp;</a></div>
						<a href={`mailto:${Constants.DOMAINE}@gmail.com`}>Contact</a>
					</LeftCol>
				</HomerContainer>
			</HomeWrapper>
		</div>
	)
}

export default Login
