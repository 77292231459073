import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import PodiumCard from '../components/PodiumCard'
import styled from 'styled-components'
import { UserContext } from '../utils/context'
import { Link } from 'react-router-dom'
import { Loader } from '../utils/style/Atoms'
import axios from "axios"
import Header from '../components/Header'
import colors from '../utils/style/colors'

const CardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
	width: 100%;
`
const ActivityContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`
const ActivityWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
export const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-bottom: 0.2em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
function Podium() {
	const { context } = useContext(UserContext);
	const [podium_data, set_podium_data] = useState({});
	const [month_podium_data, set_month_podium_data] = useState({});
	const [last_month_podium_data, set_last_month_podium_data] = useState({});
	const [notif_count, set_notif_count] = useState(0);
	const [new_chat_message, set_new_chat_message] = useState(false);
	const [isLoading, setLoading] = useState(null);
	const [is_user_connected, set_is_user_connected] = useState(false);

	useEffect(() => {
		if (!context["auth_token"] && localStorage.getItem('user')) {
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		if (context["auth_token"]) {
			set_is_user_connected(true);
		} else {
			set_is_user_connected(false);
		}
		setLoading('Chargement en cours...');
		get_podium_data();
		axios.get(`${Constants.BACKEND_URL}notification/check_user_notifs`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("check_user_notifs ", response.data);
				set_notif_count(response.data.notif_count);
				set_new_chat_message(response.data.new_chat_message);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])

	function get_podium_data() {
		console.log("get get_podium_data");
		axios.get(`${Constants.BACKEND_URL}user/podium/score`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get score : ", response.data);
				set_podium_data(response.data);
				setLoading(false);
			}).catch(error => {
				setLoading(false);
				console.error(error);
			});

		axios.get(`${Constants.BACKEND_URL}user/podium/month_score`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_n month_score : ", response.data);
				set_month_podium_data(response.data);
			}).catch(error => {
				console.error(error);
			});

		axios.get(`${Constants.BACKEND_URL}user/podium/last_month_score`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_ last_month_score : ", response.data);
				set_last_month_podium_data(response.data);
			}).catch(error => {
				console.error(error);
			});
	}

	return (
		<div>
			<Header notif_count={notif_count} new_chat_message={new_chat_message} />
			<div className="gros"><mark>&nbsp;Jeu concours : 10€ offert&nbsp;</mark> via Wero aux trois utilisateurs les plus actifs ce mois-ci ! 💶</div>
			<ActivityContainer>
				Podium du mois en cours
				{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(month_podium_data) && (
							month_podium_data?.map((user) => (
								<Link style={{ width: '100%' }} key={user._id} to={`/user/${user._id}`}>
									<PodiumCard
										content={user.title}
										user_image={`${Constants.BACKEND_URL}images/${user.image}`}
										score={user.month_score}
									/>
								</Link>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				Podium du mois dernier
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(last_month_podium_data) && (
							last_month_podium_data?.map((user) => (
								<Link style={{ width: '100%' }} key={user._id} to={`/user/${user._id}`}>
									<PodiumCard
										content={user.title}
										user_image={`${Constants.BACKEND_URL}images/${user.image}`}
										score={user.last_month_score}
									/>
								</Link>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				Podium de tous les temps
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(podium_data) && (
							podium_data?.map((user) => (
								<Link style={{ width: '100%' }} key={user._id} to={`/user/${user._id}`}>
									<PodiumCard
										content={user.title}
										user_image={`${Constants.BACKEND_URL}images/${user.image}`}
										score={user.score}
									/>
								</Link>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				{!is_user_connected && (
					<LoaderWrapper style={{ marginTop: '1em' }}>
						<StyledLink $isFullLink to={`/login`} >En voir plus</StyledLink>
					</LoaderWrapper>
				)}
				<table>
					<tbody>
						<tr>
							<td>Points</td>
							<td>Actions</td>
						</tr>
						<tr>
							<td>150</td>
							<td>Parrainer quelqu'un</td>
						</tr>
						<tr>
							<td>40</td>
							<td>Déclarer un parrain</td>
						</tr>
						<tr>
							<td>40</td>
							<td>Créer une sortie publique</td>
						</tr>
						<tr>
							<td>20</td>
							<td>Avoir un nouveau contact</td>
						</tr>
						<tr>
							<td>15</td>
							<td>Participer à une sortie publique</td>
						</tr>
						<tr>
							<td>10</td>
							<td>Avoir un participant à sa sortie publique</td>
						</tr>
						<tr>
							<td>5</td>
							<td>Demander quelqu'un en contact</td>
						</tr>
						<tr>
							<td>5</td>
							<td>Être demandé en contact</td>
						</tr>
						<tr>
							<td>3</td>
							<td>Ecrire un message dans la discussion publique</td>
						</tr>
						<tr>
							<td>3</td>
							<td>Ecrire un message dans une sortie publique</td>
						</tr>
						<tr>
							<td>1</td>
							<td>Modifier son profil</td>
						</tr>
						<tr>
							<td>1</td>
							<td>Visite de sa sortie publique</td>
						</tr>
						<tr>
							<td>1</td>
							<td>Visite de son profil</td>
						</tr>
					</tbody>
				</table>
				<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>
			</ActivityContainer>
		</div>
	)
}

export default Podium
